<template>
  <div class="agreement">
    <div>
      <a-page-header
        title="协议管理"
       class="hearStyle"
      />
      <a-table :columns="columns" :pagination='false' style="margin-top: 10px" :data-source="tableData" bordered>
        <span slot="action" slot-scope="text, record" class="action">
          <a href="javascript:;" @click="check(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.agreement.look)">查看</a>
          <a href="javascript:;" @click="edit(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.agreement.edit)">编辑</a>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as api from "@/api/userMessage";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      tableData: [],
      columns: [
        { title: "协议ID", dataIndex: "id", key: "id", align: "center", },
        { title: "协议名称", dataIndex: "agreementName", key: "agreementName", align: "center" },
        {
          title: "协议类型", dataIndex: "type", key: "type", align: "center",
          customRender: (text) => {
            if (text === 1) {
              return '通勤巴士用户协议'
            } else if (text === 2) {
              return '积分支付用户协议'
            } else {
              return 'App用户协议'
            }
          }
        },
        { title: "适用项目", dataIndex: "projectIds", key: "projectIds", width: '400px', align: "center" },
        { title: "修改时间", dataIndex: "updateAt", align: "center" },
        { title: "修改人", dataIndex: "updateId", key: "updateId", align: "center" },
      ],
      actions: [
        this.$buttonPermissionsConfig.agreement.look,
        this.$buttonPermissionsConfig.agreement.edit
      ]
    };
  },
  computed: {
    ...mapState("global", {
      user: (state) => state.user, // 获取父应用的user信息
    }),
  },
  created() {
    moment(moment()).unix();
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction());
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    ...mapActions("global", ["setGlobalState"]),
    async getTableList() {
      const res = await api.agreementList({ pageNum: "1", pageSize: "50" });
      res.data.list.forEach((element,index) => {
        element.updateAt = moment(element.updateAt).format("YYYY-MM-DD HH:mm:ss");
      });
      this.tableData = res.data.list;
    },
    check(row) {
      this.$router.push({
        path: "/agreement/detail",
        query: { id: row.id, type: 'view' },
      });
    },
    delede(row) {
      this.$confirm("请确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await api.agreementDeleted({ id: row.id });
          if (res.code === "200") {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.getTableList();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    edit(row) {
      this.$router.push({
        path: "/agreement/detail",
        query: { id: row.id },
      });
    },
    addData() {
      this.$router.push({
        path: "/agreement/detail",
      });
    },
  },
};
</script>
<style scoped>
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}
</style>
